<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Status -->
        <template #cell(type)="data">
            <b-badge pill variant="light-success" v-if="data.item.type=='Empty'">
                SERBEST YAZI
            </b-badge>
            <b-badge pill variant="light-success" v-if="data.item.type=='Single'">
                TEKLİ SEÇİM
            </b-badge>
            <b-badge pill variant="light-success" v-if="data.item.type=='Multi'">
                ÇOKLU SEÇİM
            </b-badge>
            <b-badge pill variant="light-success" v-if="data.item.type=='File'">
                DOSYA YÜKLEME
            </b-badge>
        </template>
        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-primary" v-if="data.item.type!='Empty' && data.item.type!='File'" @click="openAnswer(data.item)">
                <feather-icon icon="LinkIcon" class="mr-50" />
                <span class="align-middle">Seçenekler</span>
            </b-button>
            <b-button variant="flat-warning" @click="editSurvey(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Düzenle</span>
            </b-button>
        </template>

    </b-table>

    <!--Open Add Survey-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addSurveyPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Soru Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Soru Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addQuestionRequest.code" />
                </b-form-group>
                <b-form-group label="Soru Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addQuestionRequest.text" />
                </b-form-group>
                <b-form-group label="Soru Sırası" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addQuestionRequest.viewOrder" />
                </b-form-group>
                <b-form-group label="Soru Tipi?" label-for="from">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(type) => type.key" label="text" :options="types" v-model="addQuestionRequest.type" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addSurvey">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Open Edit Survey-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editSurveyPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Soru Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Soru Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editQuestionRequest.code" />
                </b-form-group>
                <b-form-group label="Soru Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editQuestionRequest.text" />
                </b-form-group>
                <b-form-group label="Soru Sırası" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editQuestionRequest.viewOrder" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateSurvey">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Open Answer For Question-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" width="50%" shadow backdrop no-header right v-model="openAnswerPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Soru Düzenle</h5>
                <span @click="addChoicePanel=true">
                    <feather-icon class="ml-1 cursor-pointer" icon="PlusIcon" size="16" />
                    Yeni Seçenek Ekle
                </span>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <b-table :items="answers" responsive :fields="answerFields" class="mb-0">

                <!-- Id -->
                <template #cell(id)="data">
                    <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                </template>

                <!-- CreatedAt Time -->
                <template #cell(createdAt)="data">
                    <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
                </template>

                <!-- Status -->
                <template #cell(isActive)="data">
                    <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                        {{data.item.isActive?'AKTİF':'PASİF'}}
                    </b-badge>
                </template>

                <!-- Status -->
                <template #cell(type)="data">
                    <b-badge pill variant="light-success" v-if="data.item.type=='Empty'">
                        SERBEST YAZI
                    </b-badge>
                    <b-badge pill variant="light-success" v-if="data.item.type=='Single'">
                        TEKLİ SEÇİM
                    </b-badge>
                    <b-badge pill variant="light-success" v-if="data.item.type=='Multi'">
                        ÇOKLU SEÇİM
                    </b-badge>
                </template>
                <!-- Actions -->
                <template #cell(actions)="data">
                    <b-button variant="flat-warning" @click="editAnswer(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span class="align-middle">Düzenle</span>
                    </b-button>
                </template>
            </b-table>
        </template>
    </b-sidebar>

    <!--Open Add Choices-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addChoicePanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Seçenek Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Seçenek Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addChoiceRequest.code" />
                </b-form-group>
                <b-form-group label="Seçenek Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addChoiceRequest.text" />
                </b-form-group>
                <b-form-group label="Seçenek Sırası" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addChoiceRequest.viewOrder" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addChoice">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Soru Kodu' },
                { key: 'text', label: 'Soru' },
                { key: 'type', label: 'Soru Tipi' },
                { key: "actions", label: 'İşlemler' }
            ],
            types: [{ key: 'Empty', text: 'Serbest Yazı' }, { key: 'Single', text: 'Tekli Seçim' }, { key: 'Multi', text: 'Çoklu Seçim' }, { key: 'File', text: 'Dosya Yükleme' }],
            users: [],
            addSurveyPanel: false,
            editSurveyPanel: false,
            openSurveyQuestionPanel: false,
            addQuestionRequest: {
                code: '',
                text: '',
                type: '',
                viewOrder: 0
            },
            editQuestionRequest: {
                id: 0,
                code: '',
                text: '',
                type: '',
                viewOrder: 0
            },
            questions: [],
            openAnswerPanel: false,
            answers: [],
            answerFields: [
                { key: 'id', label: 'Ref' },
                { key: 'code', label: 'Kod' },
                { key: 'text', label: 'Seçenek' },
                { key: "actions", label: 'İşlemler' }
            ],
            selectedQuestion: {},
            addChoicePanel: false,
            addChoiceRequest: {
                code: '',
                text: '',
                viewOrder: 0
            }
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addSurveyPanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Branch/SurveyQuestion/" + this.$route.params.surveyId + "/" + this.$route.params.groupId);
            this.users = users.data.data;
        },
        openSurveyQuestion(data) {
            this.questions = data.questions;
            this.openSurveyQuestionPanel = true;
        },
        editSurvey(data) {
            this.editQuestionRequest = {
                id: data.id,
                code: data.code,
                text: data.text,
                type: data.type,
                viewOrder: data.viewOrder
            };
            this.editSurveyPanel = true;
        },
        async openAnswer(question) {
            var response = await this.$http.get("Branch/SurveyQuestionChoice/" + this.$route.params.surveyId + "/" + this.$route.params.groupId + "/" + question.id);
            this.answers = response.data.data;
            this.selectedQuestion = question;
            this.openAnswerPanel = true;
        },
        addSurvey() {
            this.addQuestionRequest.groupId = this.$route.params.groupId;
            this.addQuestionRequest.surveyId = this.$route.params.surveyId;
            this.addQuestionRequest.viewOrder = parseInt(this.addQuestionRequest.viewOrder);
            var request = this.addQuestionRequest;
            this.$http.post("Branch/AddSurveyQuestion", request).then((data) => {
                    this.getData();
                    this.addSurveyPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Anket Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateSurvey() {
            this.editQuestionRequest.groupId = this.$route.params.groupId;
            this.editQuestionRequest.surveyId = this.$route.params.surveyId;
            this.editQuestionRequest.viewOrder = parseInt(this.editQuestionRequest.viewOrder);
            var request = this.editQuestionRequest;
            this.$http.put("Branch/UpdateSurveyQuestion", request).then((data) => {
                    this.getData();
                    this.editSurveyPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Anket Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        addChoice() {

            this.addChoiceRequest.groupId = this.$route.params.groupId;
            this.addChoiceRequest.surveyId = this.$route.params.surveyId;
            this.addChoiceRequest.questionId = this.selectedQuestion.id;

            this.addChoiceRequest.viewOrder = parseInt(this.addChoiceRequest.viewOrder);
            var request = this.addChoiceRequest;
            this.$http.post("Branch/AddQuestionChoice", request).then((data) => {
                    this.answers.push(data.data.data);
                    this.getData();
                    this.addChoicePanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Anket Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
